import * as GumShoe from '../../../../resources/js/vendors/GumShoe';

export default class AnchorBlock1 {

	/**
	 * Init Anchor block 1
	 */
	static init() {
		if (!document.querySelector('.anchorBlock')) {
			return;
		}

		new GumShoe('.anchorBlock__links a', {
			reflow: true,
			nested: true,
			nestedClass: 'active-parent',
			offset: () => {
				let defaultOffset      = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--anchorBlockLinksOffset')) || 20,
				    $adminBar          = document.querySelector('#wpadminbar'),
				    //@formatter:off
                    adminBarHeight = ($adminBar ? $adminBar.getBoundingClientRect().height : 0),
                    //@formatter:on
                    headerHeight       = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--headerHeight')),
                    headerClonedHeight = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--headerClonedHeight'));

				if (!headerHeight) {
					headerHeight = 0;
				}

				if (headerClonedHeight) {
					headerHeight = headerClonedHeight;
				}

				return defaultOffset + headerHeight + adminBarHeight;
			}
		});
	}

}