'use strict';
import AnchorBlock1 from '../../../wordpress-theme/theme/anchor-block/1/js/AnchorBlock1';
class App {

    constructor(settings = {})
    {
        this.init();
    }

    /**
     * Initialization of all modules
     */
    initModules()
    {
		AnchorBlock1.init();
    }

	 
    /**
     * Initialization
     */
    init()
    {
        window.addEventListener('DOMContentLoaded', () => {
            this.initModules();
        });
    }
}

new App();